/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: unknown;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: unknown;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
};

export type Appointment = BaseAppointment & {
  __typename?: 'Appointment';
  appointmentEndTime: Scalars['DateTime'];
  appointmentStartTime: Scalars['DateTime'];
  /** Other related appointments past and future excluding the current appointment */
  appointments: Array<Appointment>;
  /** callback Date */
  callbackDate?: Maybe<Scalars['DateTime']>;
  /** The appointment has ended in a non sale status this will be true */
  cancelled: Scalars['Boolean'];
  consultant?: Maybe<Consultant>;
  email?: Maybe<Scalars['String']>;
  /** Calendly Event type */
  eventType?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Id from presentation record in dynamics */
  id: Scalars['ID'];
  /** The associated Iris session record if one has been created */
  iris?: Maybe<Iris>;
  lastName: Scalars['String'];
  /** id of the associated lead */
  leadId?: Maybe<Scalars['String']>;
  /** This is the lead reference number - as a date format */
  leadReferenceNumber?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSourceCode>;
  mobile?: Maybe<Scalars['String']>;
  /** Appointment / presentation outcome */
  outcome?: Maybe<AppointmentOutcome>;
  /** Appointment / presentation outcome analysis */
  outcomeAnalysis?: Maybe<AppointmentOutcomeAnalysis>;
  /** is this a pickup sale appointment */
  pickup: Scalars['Boolean'];
  postcode?: Maybe<Scalars['String']>;
};


export type AppointmentAppointmentsArgs = {
  limitDays?: InputMaybe<Scalars['Int']>;
  noSaleReason?: InputMaybe<AppointmentNoSaleReason>;
  outcome?: InputMaybe<AppointmentOutcome>;
  pastOrFuture?: InputMaybe<PastOrFuture>;
};

/** who is cancelling this appointment */
export enum AppointmentCancelledBy {
  Ca = 'CA',
  Customer = 'CUSTOMER'
}

export type AppointmentNoSale = BaseAppointment & {
  __typename?: 'AppointmentNoSale';
  appointmentEndTime: Scalars['DateTime'];
  appointmentStartTime: Scalars['DateTime'];
  /** Other related appointments past and future excluding the current appointment */
  appointments: Array<Appointment>;
  /** The callback date to call back if the appointment is a no sale */
  callBackDate?: Maybe<Scalars['DateTime']>;
  /** callback Date */
  callbackDate?: Maybe<Scalars['DateTime']>;
  /** Can this past presentation be used to create a pickup sale from */
  canPickup: Scalars['Boolean'];
  /** The appointment has ended in a non sale status this will be true */
  cancelled: Scalars['Boolean'];
  consultant?: Maybe<Consultant>;
  email?: Maybe<Scalars['String']>;
  /** Calendly Event type */
  eventType?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Id from presentation record in dynamics */
  id: Scalars['ID'];
  /** The associated Iris session record if one has been created */
  iris?: Maybe<Iris>;
  lastName: Scalars['String'];
  /** id of the associated lead */
  leadId?: Maybe<Scalars['String']>;
  /** This is the lead reference number - as a date format */
  leadReferenceNumber?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSourceCode>;
  mobile?: Maybe<Scalars['String']>;
  /** If the appointment/presentation was no sale this will be the reason */
  noSaleReason?: Maybe<AppointmentNoSaleReason>;
  /** Appointment / presentation outcome */
  outcome?: Maybe<AppointmentOutcome>;
  /** Appointment / presentation outcome analysis */
  outcomeAnalysis?: Maybe<AppointmentOutcomeAnalysis>;
  /** is this a pickup sale appointment */
  pickup: Scalars['Boolean'];
  postcode?: Maybe<Scalars['String']>;
};


export type AppointmentNoSaleAppointmentsArgs = {
  limitDays?: InputMaybe<Scalars['Int']>;
  noSaleReason?: InputMaybe<AppointmentNoSaleReason>;
  outcome?: InputMaybe<AppointmentOutcome>;
  pastOrFuture?: InputMaybe<PastOrFuture>;
};

/** Appointment outcome anaylsis for no sale only */
export enum AppointmentNoSaleReason {
  CantAfford = 'CANT_AFFORD',
  ChildCommitmentIssues = 'CHILD_COMMITMENT_ISSUES',
  EnglishTooPoor = 'ENGLISH_TOO_POOR',
  PartnerNeedsToSee = 'PARTNER_NEEDS_TO_SEE',
  TutorWanted = 'TUTOR_WANTED',
  WantTimeToConsider = 'WANT_TIME_TO_CONSIDER'
}

/** Appointment outcome - note this value is direct from dynamics generated code */
export enum AppointmentOutcome {
  CustomerCancelnoreappoint = 'CustomerCancelnoreappoint',
  CustomerCancelreappoint = 'CustomerCancelreappoint',
  CustomerCanxreappointalreadybooked = 'CustomerCanxreappointalreadybooked',
  DeferredDeposit = 'DeferredDeposit',
  DeferredDepositNotCollected = 'DeferredDepositNotCollected',
  Noshow = 'Noshow',
  OfficeCancelSpare = 'OfficeCancelSpare',
  OfficeCancelreappoint = 'OfficeCancelreappoint',
  OfficeCanxreappointalreadybooked = 'OfficeCanxreappointalreadybooked',
  PresnosaleEnglishtoopoor = 'PresnosaleEnglishtoopoor',
  PresnosalePartnertosee = 'PresnosalePartnertosee',
  PresnosalePrivatetutorwanted = 'PresnosalePrivatetutorwanted',
  PresnosaleThinkaboutit = 'PresnosaleThinkaboutit',
  PresnosaleY11only = 'PresnosaleY11only',
  Presnosalecantafford = 'Presnosalecantafford',
  Presnosalecantaffordunemployed = 'Presnosalecantaffordunemployed',
  Presnosalechildcommitment = 'Presnosalechildcommitment',
  Presnosalereceptiononly = 'Presnosalereceptiononly',
  Presnosalespecialneeds = 'Presnosalespecialneeds',
  Sale = 'Sale'
}

/** Appointment outcome analysis - note this value is direct from dynamics generated code */
export enum AppointmentOutcomeAnalysis {
  Thinkaboutchildcommitment = 'Thinkaboutchildcommitment',
  Thinkaboutprice = 'Thinkaboutprice',
  Thinkaboutterm = 'Thinkaboutterm'
}

export type AppointmentSale = BaseAppointment & {
  __typename?: 'AppointmentSale';
  appointmentEndTime: Scalars['DateTime'];
  appointmentStartTime: Scalars['DateTime'];
  /** Other related appointments past and future excluding the current appointment */
  appointments: Array<Appointment>;
  /** callback Date */
  callbackDate?: Maybe<Scalars['DateTime']>;
  /** The appointment has ended in a non sale status this will be true */
  cancelled: Scalars['Boolean'];
  consultant?: Maybe<Consultant>;
  email?: Maybe<Scalars['String']>;
  /** Calendly Event type */
  eventType?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Id from presentation record in dynamics */
  id: Scalars['ID'];
  /** The associated Iris session record if one has been created */
  iris?: Maybe<Iris>;
  lastName: Scalars['String'];
  /** id of the associated lead */
  leadId?: Maybe<Scalars['String']>;
  /** This is the lead reference number - as a date format */
  leadReferenceNumber?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSourceCode>;
  mobile?: Maybe<Scalars['String']>;
  modules?: Maybe<Scalars['Int']>;
  /** Appointment / presentation outcome */
  outcome?: Maybe<AppointmentOutcome>;
  /** Appointment / presentation outcome analysis */
  outcomeAnalysis?: Maybe<AppointmentOutcomeAnalysis>;
  /** is this a pickup sale appointment */
  pickup: Scalars['Boolean'];
  postcode?: Maybe<Scalars['String']>;
  saleType?: Maybe<SaleType>;
};


export type AppointmentSaleAppointmentsArgs = {
  limitDays?: InputMaybe<Scalars['Int']>;
  noSaleReason?: InputMaybe<AppointmentNoSaleReason>;
  outcome?: InputMaybe<AppointmentOutcome>;
  pastOrFuture?: InputMaybe<PastOrFuture>;
};

/** Appointment status - note this is basically the presentation outcome field */
export enum AppointmentStatus {
  All = 'ALL',
  Current = 'CURRENT',
  Outcomed = 'OUTCOMED',
  OutcomedNoSale = 'OUTCOMED_NO_SALE',
  OutcomedPickup = 'OUTCOMED_PICKUP',
  OutcomedSale = 'OUTCOMED_SALE'
}

/** Enum describing what type of assessment this is */
export enum AssessmentLevel {
  CurrentYear = 'CURRENT_YEAR',
  NextYear = 'NEXT_YEAR',
  PreviousYear = 'PREVIOUS_YEAR'
}

export type BaseAppointment = {
  appointmentEndTime: Scalars['DateTime'];
  appointmentStartTime: Scalars['DateTime'];
  /** Other related appointments past and future excluding the current appointment */
  appointments: Array<Appointment>;
  /** callback Date */
  callbackDate?: Maybe<Scalars['DateTime']>;
  /** The appointment has ended in a non sale status this will be true */
  cancelled: Scalars['Boolean'];
  consultant?: Maybe<Consultant>;
  email?: Maybe<Scalars['String']>;
  /** Calendly Event type */
  eventType?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Id from presentation record in dynamics */
  id: Scalars['ID'];
  /** The associated Iris session record if one has been created */
  iris?: Maybe<Iris>;
  lastName: Scalars['String'];
  /** id of the associated lead */
  leadId?: Maybe<Scalars['String']>;
  /** This is the lead reference number - as a date format */
  leadReferenceNumber?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSourceCode>;
  mobile?: Maybe<Scalars['String']>;
  /** Appointment / presentation outcome */
  outcome?: Maybe<AppointmentOutcome>;
  /** Appointment / presentation outcome analysis */
  outcomeAnalysis?: Maybe<AppointmentOutcomeAnalysis>;
  /** is this a pickup sale appointment */
  pickup: Scalars['Boolean'];
  postcode?: Maybe<Scalars['String']>;
};


export type BaseAppointmentAppointmentsArgs = {
  limitDays?: InputMaybe<Scalars['Int']>;
  noSaleReason?: InputMaybe<AppointmentNoSaleReason>;
  outcome?: InputMaybe<AppointmentOutcome>;
  pastOrFuture?: InputMaybe<PastOrFuture>;
};

/** Override the default brand */
export enum Branding {
  Estia = 'Estia',
  EstiaLabs = 'EstiaLabs',
  Exemplar = 'Exemplar',
  Nz123 = 'Nz123'
}

export enum CardCollectionType {
  Payment = 'PAYMENT',
  SaveForLater = 'SAVE_FOR_LATER'
}

export type Child = {
  firstName: Scalars['String'];
  schoolYear: Scalars['String'];
};

export type ChildInput = {
  firstName: Scalars['String'];
  schoolYear: Scalars['String'];
};

export type ChildModel = Child & Timestamps & {
  __typename?: 'ChildModel';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  results: Array<LessonResultModel>;
  schoolYear: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Consultant = {
  __typename?: 'Consultant';
  calendlyUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  /** This will let you know if the consultant is your own login */
  me: Scalars['Boolean'];
};

/** result form looking up a user if possible */
export type ConsultantUser = {
  __typename?: 'ConsultantUser';
  /** If locked associated appointment */
  appointment?: Maybe<BaseAppointment>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Last time online */
  lastOnline?: Maybe<Scalars['DateTime']>;
  /** consultant has locked a zoom session with a customer */
  locked: Scalars['Boolean'];
  online: Scalars['Boolean'];
  /** Ordering for allocating appointments to */
  priority: Scalars['Float'];
  userid: Scalars['ID'];
  username: Scalars['String'];
};

/** Contact with phone number email address etc.. */
export type ContactWithPhone = {
  __typename?: 'ContactWithPhone';
  address: Address;
  dob: Scalars['DateTime'];
  emails: Array<Email>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phones: Array<Phone>;
  title?: Maybe<Scalars['String']>;
};

export type ContactWithPhoneInput = {
  address: AddressInput;
  dob: Scalars['DateTime'];
  emails: Array<EmailInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phones: Array<PhoneInput>;
  title?: InputMaybe<Scalars['String']>;
};

/** List of content applications for fslessonapi */
export enum ContentApplications {
  De = 'DE',
  FsMaths = 'FSMaths',
  Grammar = 'Grammar',
  /** Note you need to remove the "-" charcter from this */
  MathematicsUsa = 'MathematicsUSA'
}

export type CreatePaymentInput = {
  /** Only set this is payment is today */
  amountInCents?: InputMaybe<Scalars['Int']>;
  collectionType: CardCollectionType;
  contact: ContactWithPhoneInput;
  /** Type of Notifications to send out */
  notificationType: Array<NotificationType>;
  schedule?: InputMaybe<Array<SchedulePaymentInput>>;
};

/** Internal enums mapped to strings in iris nest for db usage */
export enum CurrentSaleTypes {
  Aps = 'APS',
  Aps24 = 'APS24',
  Cp12 = 'CP12',
  Cp126 = 'CP126',
  Elb = 'ELB',
  Elg = 'ELG',
  Elnm = 'ELNM',
  Elp = 'ELP',
  Els = 'ELS',
  Payg = 'PAYG',
  Sp01 = 'SP01',
  Sp03 = 'SP03',
  Sp06 = 'SP06',
  Sp12 = 'SP12'
}

export type Email = {
  __typename?: 'Email';
  email: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type EmailInput = {
  email: Scalars['String'];
  primary: Scalars['Boolean'];
};

/** result form looking up a user if possible */
export type EndUserCalender = {
  __typename?: 'EndUserCalender';
  /**
   * is the user allowed to connect to the consultant
   * @deprecated Not going to be used
   */
  allowedToConnect: Scalars['Boolean'];
  /** Appointment records to get info from dynamics */
  appointment: BaseAppointment;
  calendlyEventId?: Maybe<Scalars['String']>;
  calentlyInviteeId?: Maybe<Scalars['String']>;
  /** Date the user was first locked */
  firstLockedAt?: Maybe<Scalars['DateTime']>;
  /** Date the user was first seen online */
  firstOnline?: Maybe<Scalars['DateTime']>;
  /**
   * Presenter zoom url
   * @deprecated get zoom url via event
   */
  presenterZoomUrl?: Maybe<Scalars['String']>;
  /**
   * Presenter zoom url
   * @deprecated get zoom url via event
   */
  zoomUrl?: Maybe<Scalars['String']>;
};

export type EstiaConnectCustomerData = {
  __typename?: 'EstiaConnectCustomerData';
  answers?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  /** current status */
  status: EstiaConnectEvent;
  subject?: Maybe<EstiaConnectSubject>;
  subscriberId?: Maybe<Scalars['Float']>;
};

/** EstiaConnect event */
export type EstiaConnectEvent = {
  __typename?: 'EstiaConnectEvent';
  /** type of request */
  action: IrisConnectEventStatus;
  /** count of users available */
  availableCount: Scalars['Int'];
  firstLockedAt?: Maybe<Scalars['DateTime']>;
  firstOnline?: Maybe<Scalars['DateTime']>;
  /** invitee id for session */
  inviteeId: Scalars['ID'];
  lastLockedAt?: Maybe<Scalars['DateTime']>;
  /** message to show to user */
  message: Scalars['String'];
  mobileDevice?: Maybe<Scalars['Boolean']>;
  /** Reason for help today */
  reason?: Maybe<EstiaConnectHelpReason>;
  /** state of user */
  state: IrisConnectEventState;
  /** Message for tutor */
  studentsMessage?: Maybe<Scalars['String']>;
  /** Students name */
  studentsName?: Maybe<Scalars['String']>;
  subject?: Maybe<EstiaConnectSubject>;
  subscriberEmail?: Maybe<Scalars['String']>;
  subscriberId?: Maybe<Scalars['Int']>;
  subscriberName?: Maybe<Scalars['String']>;
  subscriberPhone?: Maybe<Scalars['String']>;
  /** School year */
  year?: Maybe<EstiaConnectSchoolYear>;
  /** zoom url */
  zoomUrl?: Maybe<Scalars['String']>;
};

export type EstiaConnectEventTypeUnion = EstiaConnectCustomerData | EstiaConnectRscheduledEvent;

/** reason for help with tutor session today */
export enum EstiaConnectHelpReason {
  Homework = 'HOMEWORK',
  LastSession = 'LAST_SESSION',
  LessonOnLearningPlatform = 'LESSON_ON_LEARNING_PLATFORM',
  Other = 'OTHER',
  SomethingFromClassToday = 'SOMETHING_FROM_CLASS_TODAY'
}

export type EstiaConnectRscheduledEvent = {
  __typename?: 'EstiaConnectRscheduledEvent';
  date: Scalars['DateTime'];
  inviteeUuid: Scalars['String'];
};

/** list of school years for dropdown */
export enum EstiaConnectSchoolYear {
  Year1 = 'YEAR1',
  Year2 = 'YEAR2',
  Year3 = 'YEAR3',
  Year4 = 'YEAR4',
  Year5 = 'YEAR5',
  Year6 = 'YEAR6',
  Year7 = 'YEAR7',
  Year8 = 'YEAR8',
  Year9 = 'YEAR9',
  Year10 = 'YEAR10',
  Year11 = 'YEAR11',
  Year12 = 'YEAR12'
}

/** Estia tutors connection type of subject for */
export enum EstiaConnectSubject {
  English = 'ENGLISH',
  MathematicsPrimary = 'MATHEMATICS_PRIMARY',
  MathematicsSecondary = 'MATHEMATICS_SECONDARY'
}

export type EventLogItem = {
  __typename?: 'EventLogItem';
  _id: Scalars['ID'];
  action: Scalars['String'];
  actionPoint: Scalars['String'];
  documentId: Scalars['String'];
  position: Position;
  time: Scalars['DateTime'];
  title: Scalars['String'];
};

export type Iris = {
  __typename?: 'Iris';
  _id: Scalars['ID'];
  appointment?: Maybe<BaseAppointment>;
  /** associated assessment Id */
  assessmentId?: Maybe<Scalars['String']>;
  consultant?: Maybe<Consultant>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  eventLog: Array<EventLogItem>;
  mode: IrisMode;
  mutations: Array<MutationItem>;
  presentationFinishedAt?: Maybe<Scalars['DateTime']>;
  /** The iris vuex state */
  state?: Maybe<Scalars['JSONObject']>;
  status: IrisStatus;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
  username: Scalars['String'];
  validTo: Scalars['DateTime'];
};

/** IrisConnect event */
export type IrisConnectEvent = {
  __typename?: 'IrisConnectEvent';
  /** type of request */
  action: IrisConnectEventStatus;
  /** Associated customer if appointment is locked */
  appointment?: Maybe<BaseAppointment>;
  /** count of users available */
  availableCount: Scalars['Int'];
  /** Associated consultant if appointment is locked */
  consultant?: Maybe<ConsultantUser>;
  /** message to show to user */
  message: Scalars['String'];
  mobileDevice?: Maybe<Scalars['Boolean']>;
  /** presentation id for session */
  presentationId: Scalars['ID'];
  /** state of user */
  state: IrisConnectEventState;
  /** type of user connected */
  userType?: Maybe<IrisConnectUserType>;
  /** zoom url */
  zoomUrl?: Maybe<Scalars['String']>;
};

/** Iris Connect Event State */
export enum IrisConnectEventState {
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED'
}

/** Iris Connect Event Status */
export enum IrisConnectEventStatus {
  ConsultantFollowupOutcome = 'CONSULTANT_FOLLOWUP_OUTCOME',
  ConsultantOffline = 'CONSULTANT_OFFLINE',
  ConsultantOnline = 'CONSULTANT_ONLINE',
  ConsultantUpdate = 'CONSULTANT_UPDATE',
  CustomerOffline = 'CUSTOMER_OFFLINE',
  CustomerOnline = 'CUSTOMER_ONLINE',
  CustomerUpdate = 'CUSTOMER_UPDATE',
  ZoomRoomReady = 'ZOOM_ROOM_READY'
}

/** Type of user connecting */
export enum IrisConnectUserType {
  AppointmentMissed = 'APPOINTMENT_MISSED',
  AppointmentOnline = 'APPOINTMENT_ONLINE',
  PriorityAppointmentMissed = 'PRIORITY_APPOINTMENT_MISSED',
  PriorityAppointmentOnline = 'PRIORITY_APPOINTMENT_ONLINE'
}

/** Iris mode for the session */
export enum IrisMode {
  /** Normal iris sale */
  Normal = 'NORMAL',
  /** Pickup sale mode - cancelling this won't do anything, a sale will create a new preseitation record */
  Pickup = 'PICKUP',
  /** Iris in resigning documents mode - no attached lead or presentation */
  Resigning = 'RESIGNING'
}

/** Iris sales event */
export type IrisSalesEvent = {
  __typename?: 'IrisSalesEvent';
  /** type of request */
  action: IrisSalesState;
  /** Current numbet of active pages open */
  activePagesOpen: Scalars['Float'];
  /** id of request session */
  id: Scalars['ID'];
  /** message to show to user */
  message: Scalars['String'];
  position?: Maybe<Position>;
  /** Time the event was sent */
  sentAt: Scalars['DateTime'];
  /** Who sent the request */
  sentBy?: Maybe<Scalars['String']>;
};

/** Customer accecting sales terms and conditions state */
export enum IrisSalesState {
  /** Customer Accected Terms and conditions */
  CustomerAccept = 'CUSTOMER_ACCEPT',
  /** Any sort of activity on page, clicks etc.. */
  CustomerActivityOnPage = 'CUSTOMER_ACTIVITY_ON_PAGE',
  /** Customer closed page (socket closed) */
  CustomerClosedPage = 'CUSTOMER_CLOSED_PAGE',
  /** Have got location from customer */
  CustomerHaveLocation = 'CUSTOMER_HAVE_LOCATION',
  /** Customer opened page (socket opened) */
  CustomerOpenedPage = 'CUSTOMER_OPENED_PAGE',
  /** SMS Sent via gateway */
  SmsSent = 'SMS_SENT'
}

/** Outcome/Status for iris session */
export enum IrisStatus {
  /** Active and not resolved - this is the default for new sessions */
  Active = 'ACTIVE',
  /** Iris session was cancelled */
  Cancelled = 'CANCELLED',
  /** Iris session finished and successfully completed */
  Finished = 'FINISHED'
}

export type IrisStoreLog = {
  __typename?: 'IrisStoreLog';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  /** the associated iris record */
  iris: Iris;
  mutations: Array<MutationItem>;
  /** The iris vuex state */
  state: Scalars['JSONObject'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
  username: Scalars['String'];
};

/** Lead Source Code */
export enum LeadSourceCode {
  AdWords = 'AdWords',
  BlueLightCard = 'BlueLightCard',
  CAgenerated = 'CAgenerated',
  Calendly = 'Calendly',
  CustomerReferral = 'CustomerReferral',
  EmailCampaign = 'EmailCampaign',
  ExhibitionIhs = 'ExhibitionIHS',
  GcseOnline = 'GCSEOnline',
  GcseTelesales = 'GCSETelesales',
  LiveChat = 'LiveChat',
  MarketingCampaign = 'MarketingCampaign',
  MathsforFree = 'MathsforFree',
  Other = 'Other',
  OtherDigital = 'OtherDigital',
  Pressadvertising = 'Pressadvertising',
  PrimaryTimes = 'PrimaryTimes',
  PublicRelations = 'PublicRelations',
  PurchasedMailingList = 'PurchasedMailingList',
  RadioAdvert = 'RadioAdvert',
  SchoolCampaigns = 'SchoolCampaigns',
  Schooldistribution = 'Schooldistribution',
  Seminar = 'Seminar',
  SocialMedia = 'SocialMedia',
  Telephoneenquiry = 'Telephoneenquiry',
  TradeShow = 'TradeShow',
  Web = 'Web',
  Website = 'Website',
  WordofMouth = 'WordofMouth'
}

export type LessonResult = {
  app: ContentApplications;
  assessment: Scalars['String'];
  correct: Scalars['Int'];
  /** is this the final assessment in the series? */
  finalAssessment: Scalars['Boolean'];
  incorrect: Scalars['Int'];
  level: AssessmentLevel;
  mastery: Scalars['Float'];
  questions: Scalars['Int'];
  skipped: Scalars['Int'];
  title: Scalars['String'];
};

export type LessonResultInput = {
  app: ContentApplications;
  assessment: Scalars['String'];
  correct: Scalars['Int'];
  data: Array<QuestionAnswerDataInput>;
  /** is this the final assessment in the series? */
  finalAssessment: Scalars['Boolean'];
  incorrect: Scalars['Int'];
  level: AssessmentLevel;
  mastery: Scalars['Float'];
  questions: Scalars['Int'];
  skipped: Scalars['Int'];
  title: Scalars['String'];
};

export type LessonResultModel = LessonResult & Timestamps & {
  __typename?: 'LessonResultModel';
  _id: Scalars['ID'];
  app: ContentApplications;
  assessment: Scalars['String'];
  correct: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  data: Array<QuestionAnswerDataModel>;
  /** is this the final assessment in the series? */
  finalAssessment: Scalars['Boolean'];
  incorrect: Scalars['Int'];
  level: AssessmentLevel;
  mastery: Scalars['Float'];
  questions: Scalars['Int'];
  skipped: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Type of message sent */
export enum MessageType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type Mutation = {
  __typename?: 'Mutation';
  cancelAppointment: AppointmentNoSale;
  /**
   * This will attempt to find an existing iris record by presentationid or previous iris id
   * If one is found state is never updated.
   * Otherwise if inserting a new iris state is set if supplied
   */
  createIris: Iris;
  createSchoolLead: SchoolLead;
  /** Create a student/child to allow saving assessment results against */
  createStudent: ChildModel;
  deleteSubscriberRecords: Scalars['Int'];
  estiaConnectGetZoomUrls?: Maybe<EstiaConnectEvent>;
  estiaConnectTakeStudent?: Maybe<EstiaConnectEvent>;
  estiaConnectUnlockSession?: Maybe<EstiaConnectEvent>;
  /** Use this to outcome the sale */
  finishAppointment: AppointmentSale;
  /** update the current user of the iris session */
  lockIrisSession: Iris;
  /** call this every 5-10 seconds to save all vuex state remotely - will also update main iris state */
  logIrisState: IrisStoreLog;
  logPosition: Scalars['ID'];
  logUserZoomClick: Scalars['Boolean'];
  outcomeAppointment: Appointment;
  paymentPageLoaded?: Maybe<Payment>;
  presentationFinished: Iris;
  /** Attempt to close recurly account - if any payment has been made then notify admin via email */
  recurlyCloseAccount: Scalars['String'];
  /** Send events from customer to consultant */
  salesConfirmationEventPublish: IrisSalesEvent;
  salesConfirmationInit: SaleConfirmation;
  /** resend the final email to the customer */
  salesConfirmationResendEmail: Scalars['Boolean'];
  /** resend the sms to the customer */
  salesConfirmationResendSms: SaleConfirmation;
  /** send the final emails to the customer */
  salesConfirmationSendEmails: SaleConfirmation;
  /** Save an assessment result */
  saveStudentAssessmentResult: LessonResultModel;
  schoolDistributionConfirmation: SchoolDistribution;
  schoolDistributionNotCompleted: SchoolDistribution;
  /** send email/sms to customer with link to input credit card details */
  sendRemotePayment: Payment;
  /** Send a sms */
  sendSms: Scalars['JSONObject'];
  /** send a whiteboard request */
  sendWhiteboardRequest?: Maybe<Scalars['ID']>;
  startZoomSession?: Maybe<IrisConnectEvent>;
  stripeSendPaymentConfirmationEmails: Scalars['Boolean'];
  stripeSetupPaymentIntent: StripePaymentIntent;
  unlockSession?: Maybe<IrisConnectEvent>;
  updateConsultantScore?: Maybe<ConsultantUser>;
  /** Update iris status - can either be finsihed or cancelled */
  updateIrisStatus: Iris;
  /** push a message to session channel */
  whiteboardSession?: Maybe<WhiteboardSession>;
};


export type MutationCancelAppointmentArgs = {
  appointmentId: Scalars['ID'];
  cancelledBy?: InputMaybe<AppointmentCancelledBy>;
  haveReappointed?: InputMaybe<Scalars['Boolean']>;
  noSaleReason?: InputMaybe<AppointmentNoSaleReason>;
  toBeReappointed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateIrisArgs = {
  assessmentId?: InputMaybe<Scalars['String']>;
  mode: IrisMode;
  presentationId?: InputMaybe<Scalars['String']>;
  prevIrisId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['JSONObject']>;
  validTo?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateSchoolLeadArgs = {
  lead: SchoolLeadInput;
};


export type MutationCreateStudentArgs = {
  leadId: Scalars['ID'];
  student: ChildInput;
};


export type MutationDeleteSubscriberRecordsArgs = {
  subscriberId: Scalars['Int'];
};


export type MutationEstiaConnectUnlockSessionArgs = {
  tutorUserId?: InputMaybe<Scalars['Int']>;
};


export type MutationFinishAppointmentArgs = {
  appointmentId: Scalars['ID'];
  depositAmount: Scalars['Float'];
  pickup?: InputMaybe<Scalars['Boolean']>;
  saleType: SaleType;
  volumes: Scalars['Int'];
};


export type MutationLockIrisSessionArgs = {
  id: Scalars['ID'];
};


export type MutationLogIrisStateArgs = {
  id: Scalars['ID'];
  mutations?: InputMaybe<Array<MutationItemInput>>;
  state: Scalars['JSONObject'];
};


export type MutationLogPositionArgs = {
  id: Scalars['ID'];
  position: PositionInput;
};


export type MutationLogUserZoomClickArgs = {
  linkType: ZoomLinkType;
  presentationId: Scalars['String'];
};


export type MutationOutcomeAppointmentArgs = {
  callbackDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  outcome: AppointmentOutcome;
  outcomeAnalysis?: InputMaybe<AppointmentOutcomeAnalysis>;
};


export type MutationPaymentPageLoadedArgs = {
  id: Scalars['ID'];
};


export type MutationPresentationFinishedArgs = {
  id: Scalars['ID'];
};


export type MutationRecurlyCloseAccountArgs = {
  subscriberId: Scalars['Int'];
};


export type MutationSalesConfirmationEventPublishArgs = {
  action: IrisSalesState;
  id: Scalars['ID'];
  message: Scalars['String'];
  position?: InputMaybe<PositionInput>;
};


export type MutationSalesConfirmationInitArgs = {
  data: SaleConfirmationData;
};


export type MutationSalesConfirmationResendEmailArgs = {
  altEmail?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  instituteCode: Scalars['String'];
  lastName: Scalars['String'];
  parentUserId: Scalars['String'];
  serverBase: Scalars['String'];
};


export type MutationSalesConfirmationResendSmsArgs = {
  id: Scalars['ID'];
  type?: InputMaybe<MessageType>;
};


export type MutationSalesConfirmationSendEmailsArgs = {
  altEmail?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  mandatePdfUrl?: InputMaybe<Scalars['String']>;
};


export type MutationSaveStudentAssessmentResultArgs = {
  leadId: Scalars['ID'];
  result: LessonResultInput;
  studentId: Scalars['ID'];
};


export type MutationSchoolDistributionConfirmationArgs = {
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};


export type MutationSchoolDistributionNotCompletedArgs = {
  id: Scalars['ID'];
  newDate?: InputMaybe<Scalars['DateTime']>;
  otherReason?: InputMaybe<Scalars['String']>;
  reason: SchoolDistributionCompletedReason;
};


export type MutationSendRemotePaymentArgs = {
  payment: CreatePaymentInput;
};


export type MutationSendSmsArgs = {
  message: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationSendWhiteboardRequestArgs = {
  action: WhiteboardRequestAction;
  message: Scalars['String'];
  roomName?: InputMaybe<Scalars['String']>;
  sentBy?: InputMaybe<Scalars['String']>;
  userid?: InputMaybe<Scalars['ID']>;
};


export type MutationStripeSendPaymentConfirmationEmailsArgs = {
  amount: Scalars['Float'];
  paymentIntentId: Scalars['String'];
  subscriberEmail: Scalars['String'];
  subscriberFirstName: Scalars['String'];
  subscriberId: Scalars['Int'];
  subscriberLastName: Scalars['String'];
  subscriberPostcode: Scalars['String'];
};


export type MutationStripeSetupPaymentIntentArgs = {
  amountInCents: Scalars['Int'];
  subscriberId: Scalars['Int'];
};


export type MutationUnlockSessionArgs = {
  consultantUserId?: InputMaybe<Scalars['Int']>;
  force?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateConsultantScoreArgs = {
  id: Scalars['ID'];
  score: Scalars['Float'];
};


export type MutationUpdateIrisStatusArgs = {
  id: Scalars['ID'];
  status: IrisStatus;
};


export type MutationWhiteboardSessionArgs = {
  data?: InputMaybe<Scalars['String']>;
  status: WhiteboardSessionEnum;
  userid?: InputMaybe<Scalars['ID']>;
};

export type MutationItem = {
  __typename?: 'MutationItem';
  _id: Scalars['ID'];
  date: Scalars['DateTime'];
  payload?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
};

export type MutationItemInput = {
  date: Scalars['DateTime'];
  payload?: InputMaybe<Scalars['JSON']>;
  type: Scalars['String'];
};

export enum NotificationType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export enum PastOrFuture {
  All = 'ALL',
  Future = 'FUTURE',
  Past = 'PAST'
}

export type Payment = {
  __typename?: 'Payment';
  _id: Scalars['ID'];
  /** Only set this is payment is today */
  amountInCents?: Maybe<Scalars['Int']>;
  collectionType: CardCollectionType;
  contact: ContactWithPhone;
  /** Type of Notifications to send out */
  notificationType: Array<NotificationType>;
  paymentId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Array<SchedulePayment>>;
  state: RemotePaymentState;
};

/** Payment frequency */
export enum PaymentFrequency {
  Fortnight = 'FORTNIGHT',
  Fourweeks = 'FOURWEEKS',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type PaymentInput = {
  _id: Scalars['ID'];
  /** Only set this is payment is today */
  amountInCents?: InputMaybe<Scalars['Int']>;
  collectionType: CardCollectionType;
  contact: ContactWithPhoneInput;
  /** Type of Notifications to send out */
  notificationType: Array<NotificationType>;
  paymentId?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Array<SchedulePaymentInput>>;
  state: RemotePaymentState;
};

export type Phone = {
  __typename?: 'Phone';
  phone: Scalars['String'];
  type: PhoneType;
};

export type PhoneInput = {
  phone: Scalars['String'];
  type: PhoneType;
};

export enum PhoneType {
  Home = 'HOME',
  Mobile = 'MOBILE',
  Work = 'WORK'
}

export type Position = {
  __typename?: 'Position';
  accuracy?: Maybe<Scalars['Float']>;
  hasPosition: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  message: Scalars['String'];
  timestamp: Scalars['String'];
};

export type PositionInput = {
  accuracy?: InputMaybe<Scalars['Float']>;
  hasPosition: Scalars['Boolean'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  message: Scalars['String'];
  timestamp: Scalars['String'];
};

export type ProductFeature = {
  __typename?: 'ProductFeature';
  /** count of feature - usually number of modules */
  count: Scalars['Int'];
  /** name of revision course */
  description: Scalars['String'];
  /** does this include all of the feature (ie the count is at maximum number) */
  includesAll: Scalars['Boolean'];
  /** Type of feature */
  type: ProductType;
};

export type ProductFeatureInput = {
  /** count of feature - usually number of modules */
  count: Scalars['Int'];
  /** name of revision course */
  description: Scalars['String'];
  /** does this include all of the feature (ie the count is at maximum number) */
  includesAll: Scalars['Boolean'];
  /** Type of feature */
  type: ProductType;
};

/** Types of products offered */
export enum ProductType {
  EarlyReading = 'EARLY_READING',
  Literacy = 'LITERACY',
  Maths = 'MATHS',
  MathsRevision = 'MATHS_REVISION',
  Membership = 'MEMBERSHIP',
  PlatformAccess = 'PLATFORM_ACCESS',
  TeacherHelpline = 'TEACHER_HELPLINE',
  TeacherSessions = 'TEACHER_SESSIONS'
}

export type Query = {
  __typename?: 'Query';
  appointment: BaseAppointment;
  appointmentByCalendlyId?: Maybe<EndUserCalender>;
  appointmentByPresentationId?: Maybe<EndUserCalender>;
  appointmentFindByPhoneOrEmail?: Maybe<EndUserCalender>;
  /** Get a list of appointments for the user */
  appointments: Array<BaseAppointment>;
  /** Get current user or selected consultant */
  consultant?: Maybe<Consultant>;
  /** Get a list of consultants or only yourself if not permitted */
  consultants: Array<Consultant>;
  consultantsWaiting: IrisConnectEvent;
  currentTimestamp: Scalars['Float'];
  customersWaiting: IrisConnectEvent;
  estiaConnectAppointment?: Maybe<EstiaConnectEventTypeUnion>;
  estiaConnectDynamicsAccountUrl?: Maybe<Scalars['String']>;
  estiaStudentsWaiting: EstiaConnectEvent;
  estiaTutorsWaiting: EstiaConnectEvent;
  /** Find if a subscriberid account was created using new confirmation system and if so return the id */
  getSaleConfirmationId?: Maybe<Scalars['ID']>;
  /** List of iris sessions */
  iris: Array<Iris>;
  irisById?: Maybe<Iris>;
  irisBySubscriberId?: Maybe<Iris>;
  irisConnectConsultantsList: Array<ConsultantUser>;
  irisConnectFutureAppointments: Array<TimeslotInterval>;
  payment?: Maybe<Payment>;
  saleConfirmationById: SaleConfirmation;
  schoolDistribution?: Maybe<SchoolDistribution>;
  schoolLead?: Maybe<SchoolLead>;
  tutorStudentOnlineStatus: StudentStatus;
  zoomUserAccountExists: Scalars['Boolean'];
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentByCalendlyIdArgs = {
  inviteeId: Scalars['String'];
};


export type QueryAppointmentByPresentationIdArgs = {
  presentationId: Scalars['String'];
};


export type QueryAppointmentFindByPhoneOrEmailArgs = {
  search: Scalars['String'];
};


export type QueryAppointmentsArgs = {
  consultantId?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  limitToOwn?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status: AppointmentStatus;
};


export type QueryConsultantArgs = {
  consultantId?: InputMaybe<Scalars['ID']>;
};


export type QueryConsultantsWaitingArgs = {
  presentationId: Scalars['String'];
  userAgent: Scalars['String'];
};


export type QueryEstiaConnectAppointmentArgs = {
  forceEmail?: InputMaybe<Scalars['Boolean']>;
  inviteeUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  subject?: InputMaybe<EstiaConnectSubject>;
};


export type QueryEstiaConnectDynamicsAccountUrlArgs = {
  subscriberId: Scalars['Int'];
};


export type QueryEstiaTutorsWaitingArgs = {
  inviteeId: Scalars['String'];
};


export type QueryGetSaleConfirmationIdArgs = {
  subscriberId: Scalars['Int'];
};


export type QueryIrisArgs = {
  consultantId?: InputMaybe<Scalars['String']>;
  showOwnSessions?: InputMaybe<Scalars['Boolean']>;
};


export type QueryIrisByIdArgs = {
  id: Scalars['ID'];
};


export type QueryIrisBySubscriberIdArgs = {
  subscriberId: Scalars['Int'];
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QuerySaleConfirmationByIdArgs = {
  id: Scalars['ID'];
};


export type QuerySchoolDistributionArgs = {
  id: Scalars['ID'];
};


export type QuerySchoolLeadArgs = {
  leadId: Scalars['ID'];
};


export type QueryTutorStudentOnlineStatusArgs = {
  userid: Scalars['ID'];
};

export type QuestionAnswerData = {
  attempts: Scalars['Int'];
  correct_responses: Array<Scalars['String']>;
  dateanswered: Scalars['DateTime'];
  dateshown: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  learner_response?: Maybe<Scalars['String']>;
  lesson: Scalars['String'];
  lessonquestion: Scalars['Int'];
  question: Scalars['Int'];
  result?: Maybe<Scalars['Boolean']>;
  solutionavailable: Scalars['Boolean'];
  type: Scalars['String'];
  weighting: Scalars['String'];
};

export type QuestionAnswerDataInput = {
  attempts: Scalars['Int'];
  correct_responses: Array<Scalars['String']>;
  dateanswered: Scalars['DateTime'];
  dateshown: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  learner_response?: InputMaybe<Scalars['String']>;
  lesson: Scalars['String'];
  lessonquestion: Scalars['Int'];
  question: Scalars['Int'];
  result?: InputMaybe<Scalars['Boolean']>;
  solutionavailable: Scalars['Boolean'];
  type: Scalars['String'];
  weighting: Scalars['String'];
};

export type QuestionAnswerDataModel = QuestionAnswerData & {
  __typename?: 'QuestionAnswerDataModel';
  _id: Scalars['ID'];
  attempts: Scalars['Int'];
  correct_responses: Array<Scalars['String']>;
  dateanswered: Scalars['DateTime'];
  dateshown: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  learner_response?: Maybe<Scalars['String']>;
  lesson: Scalars['String'];
  lessonquestion: Scalars['Int'];
  question: Scalars['Int'];
  result?: Maybe<Scalars['Boolean']>;
  solutionavailable: Scalars['Boolean'];
  type: Scalars['String'];
  weighting: Scalars['String'];
};

export enum RemotePaymentState {
  /** client has just left the page */
  Abandoned = 'ABANDONED',
  /** Cancelled state */
  Cancelled = 'CANCELLED',
  /** stripe payment failure */
  Failure = 'FAILURE',
  /** initial state */
  Init = 'INIT',
  /** link sent to remote client */
  LinkSent = 'LINK_SENT',
  /** client has loaded page */
  PageLoaded = 'PAGE_LOADED',
  /** stripe payment is processing */
  Processing = 'PROCESSING',
  /** stripe payment was successful */
  Success = 'SUCCESS'
}

export type SaleConfirmation = {
  __typename?: 'SaleConfirmation';
  /** number of client pages open */
  activePagesOpen: Scalars['Float'];
  brand?: Maybe<Branding>;
  /** once set this is marked as confirmed */
  confirmedAt?: Maybe<Scalars['DateTime']>;
  creditcardLast4?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** list of product features if required */
  features?: Maybe<Array<ProductFeature>>;
  /** First payment date */
  firstMonthlyPayment?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  initialPaymentAmount: Scalars['Float'];
  initialPaymentDate?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  /** What type of message was sent */
  messageType: MessageType;
  minimumTerm: Scalars['Int'];
  /** regular payment amount */
  monthlyPayment?: Maybe<Scalars['Float']>;
  parentInitialPassword: Scalars['String'];
  parentInitialUsername: Scalars['String'];
  phoneNumber: Scalars['String'];
  regHitId: Scalars['Int'];
  regularPaymentFrequency?: Maybe<PaymentFrequency>;
  saleType: CurrentSaleTypes;
  shortUrl: Scalars['String'];
  /** Do not send any communication to the customer for confirmations */
  silent?: Maybe<Scalars['Boolean']>;
  subscriberId: Scalars['Int'];
  subscriptionEndDate?: Maybe<Scalars['DateTime']>;
};

export type SaleConfirmationData = {
  brand?: InputMaybe<Branding>;
  creditcardLast4?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** list of product features if required */
  features?: InputMaybe<Array<ProductFeatureInput>>;
  /** First payment date */
  firstMonthlyPayment?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  initialPaymentAmount: Scalars['Float'];
  initialPaymentDate?: InputMaybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  minimumTerm: Scalars['Int'];
  /** regular payment amount */
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  parentInitialPassword: Scalars['String'];
  parentInitialUsername: Scalars['String'];
  phoneNumber: Scalars['String'];
  regHitId: Scalars['Int'];
  regularPaymentFrequency?: InputMaybe<PaymentFrequency>;
  saleType: CurrentSaleTypes;
  /** Do not send any communication to the customer for confirmations */
  silent?: InputMaybe<Scalars['Boolean']>;
  subscriberId: Scalars['Int'];
  subscriptionEndDate?: InputMaybe<Scalars['DateTime']>;
};

export enum SaleType {
  Aps = 'APS',
  Aps6 = 'APS6',
  Aps12 = 'APS12',
  Cp12 = 'CP12',
  Cp126 = 'CP126',
  Cash = 'Cash',
  Gcse = 'GCSE',
  LabsBronze = 'LabsBronze',
  LabsGold = 'LabsGold',
  LabsNonmember = 'LabsNonmember',
  LabsPlatinum = 'LabsPlatinum',
  LabsSilver = 'LabsSilver',
  Payg = 'PAYG',
  Sp01 = 'SP01',
  Sp03 = 'SP03',
  Sp06 = 'SP06',
  Sp10Lifetime = 'SP10Lifetime',
  Sp12 = 'SP12',
  Sp30 = 'SP30',
  Unknown922840000 = 'Unknown922840000'
}

export type SchedulePayment = {
  __typename?: 'SchedulePayment';
  amountInCents: Scalars['Int'];
  dateOfCollection: Scalars['DateTime'];
  description: Scalars['String'];
};

export type SchedulePaymentInput = {
  amountInCents: Scalars['Int'];
  dateOfCollection: Scalars['DateTime'];
  description: Scalars['String'];
};

export type SchoolDistribution = {
  __typename?: 'SchoolDistribution';
  _id: Scalars['ID'];
  completed: Scalars['Boolean'];
  schoolName: Scalars['String'];
  type: SchoolDistributionType;
};

export enum SchoolDistributionCompletedReason {
  ChangedMind = 'CHANGED_MIND',
  Completed = 'COMPLETED',
  DelayedContact = 'DELAYED_CONTACT',
  DelayedWithDate = 'DELAYED_WITH_DATE',
  Other = 'OTHER',
  TechnicalProblem = 'TECHNICAL_PROBLEM'
}

export enum SchoolDistributionType {
  EmailNoAttachment = 'EMAIL_NO_ATTACHMENT',
  TextMessage = 'TEXT_MESSAGE'
}

export type SchoolLead = SchoolLeadInterface & {
  __typename?: 'SchoolLead';
  _id: Scalars['ID'];
  /**
   * User elected to do assessment at time of lead creation
   * @deprecated no longer required with split flow remove after 18 December 2021
   */
  assessment?: Maybe<Scalars['Boolean']>;
  /** If set this will send an email with a link to {URL}/assessments/{LEAD_ID} */
  baseUrl?: Maybe<Scalars['String']>;
  /** Campaign name - will in turn mark this as a marketing lead */
  campaignName?: Maybe<Scalars['String']>;
  child?: Maybe<ChildModel>;
  children: Array<ChildModel>;
  distributionType?: Maybe<SchoolDistributionType>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  leadid?: Maybe<Scalars['String']>;
  mobilePhone: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  /** Source schools name */
  schoolName?: Maybe<Scalars['String']>;
  /** SLO code which maps to a user in dynamics - starts from 8000 */
  sloCode?: Maybe<Scalars['Int']>;
  sourceCode?: Maybe<LeadSourceCode>;
  sourceUrl?: Maybe<Scalars['String']>;
  /** Source schools postcode */
  territory?: Maybe<Scalars['String']>;
};


export type SchoolLeadChildArgs = {
  childId: Scalars['ID'];
};

/** Create a new school lead */
export type SchoolLeadInput = {
  /** User elected to do assessment at time of lead creation */
  assessment?: InputMaybe<Scalars['Boolean']>;
  /** If set this will send an email with a link to {URL}/assessments/{LEAD_ID} */
  baseUrl?: InputMaybe<Scalars['String']>;
  /** Campaign name - will in turn mark this as a marketing lead */
  campaignName?: InputMaybe<Scalars['String']>;
  distributionType?: InputMaybe<SchoolDistributionType>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobilePhone: Scalars['String'];
  postcode?: InputMaybe<Scalars['String']>;
  /** Source schools name */
  schoolName?: InputMaybe<Scalars['String']>;
  /** SLO code which maps to a user in dynamics - starts from 8000 */
  sloCode?: InputMaybe<Scalars['Int']>;
  sourceCode?: InputMaybe<LeadSourceCode>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  /** Source schools postcode */
  territory?: InputMaybe<Scalars['String']>;
};

/** Required input fields to create a school lead */
export type SchoolLeadInterface = {
  /**
   * User elected to do assessment at time of lead creation
   * @deprecated no longer required with split flow remove after 18 December 2021
   */
  assessment?: Maybe<Scalars['Boolean']>;
  /** If set this will send an email with a link to {URL}/assessments/{LEAD_ID} */
  baseUrl?: Maybe<Scalars['String']>;
  /** Campaign name - will in turn mark this as a marketing lead */
  campaignName?: Maybe<Scalars['String']>;
  distributionType?: Maybe<SchoolDistributionType>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobilePhone: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  /** Source schools name */
  schoolName?: Maybe<Scalars['String']>;
  /** SLO code which maps to a user in dynamics - starts from 8000 */
  sloCode?: Maybe<Scalars['Int']>;
  sourceCode?: Maybe<LeadSourceCode>;
  sourceUrl?: Maybe<Scalars['String']>;
  /** Source schools postcode */
  territory?: Maybe<Scalars['String']>;
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  clientSecret: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobilePhone: Scalars['String'];
  postCode: Scalars['String'];
};

export type StudentStatus = {
  __typename?: 'StudentStatus';
  status: StudentStatusEnum;
  userid: Scalars['ID'];
};

export enum StudentStatusEnum {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export type Subscription = {
  __typename?: 'Subscription';
  consultantUserUpdated: ConsultantUser;
  estiaConnectEvent: EstiaConnectEvent;
  estiaConnectEventTutor: EstiaConnectEvent;
  irisConnectEvent: IrisConnectEvent;
  irisConnectEventConsultant: IrisConnectEvent;
  paymentUpdated: Payment;
  salesConfirmationEvent: IrisSalesEvent;
  /** Student clients start and close request for starting tutor session */
  studentWhiteboardRequest: WhiteboardRequest;
  /** student client online / offline status */
  tutorStudentOnlineStatus: StudentStatus;
  /** Whiteboard session channel */
  whiteboardSession: WhiteboardSession;
};


export type SubscriptionEstiaConnectEventArgs = {
  inviteeId: Scalars['ID'];
  message: Scalars['String'];
  mobileDevice: Scalars['Boolean'];
  name: Scalars['String'];
  reason: EstiaConnectHelpReason;
  schoolYear: EstiaConnectSchoolYear;
  subject: EstiaConnectSubject;
  subscriberEmail: Scalars['String'];
  subscriberId?: InputMaybe<Scalars['Int']>;
  subscriberName: Scalars['String'];
  subscriberPhone?: InputMaybe<Scalars['String']>;
};


export type SubscriptionEstiaConnectEventTutorArgs = {
  subjects: Array<EstiaConnectSubject>;
};


export type SubscriptionIrisConnectEventArgs = {
  mobileDevice: Scalars['Boolean'];
  presentationId: Scalars['ID'];
  userAgent: Scalars['String'];
};


export type SubscriptionPaymentUpdatedArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSalesConfirmationEventArgs = {
  id: Scalars['ID'];
};


export type SubscriptionTutorStudentOnlineStatusArgs = {
  userid: Scalars['ID'];
};


export type SubscriptionWhiteboardSessionArgs = {
  userid?: InputMaybe<Scalars['ID']>;
};

/** IrisConnect appointment timeslot */
export type TimeslotInterval = {
  __typename?: 'TimeslotInterval';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type Timestamps = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** whiteboard request */
export type WhiteboardRequest = {
  __typename?: 'WhiteboardRequest';
  /** type of request */
  action: WhiteboardRequestAction;
  /** message to show to user */
  message: Scalars['String'];
  /** room name to use */
  roomName?: Maybe<Scalars['String']>;
  /** who is sending the request */
  sentBy?: Maybe<Scalars['String']>;
  /** userid to send request to */
  userid?: Maybe<Scalars['ID']>;
};

/** Type of action for whiteboard request */
export enum WhiteboardRequestAction {
  /** Whiteboard request acknologed by student */
  Ack = 'ACK',
  /** Cancel action either by tutor or student */
  Cancel = 'CANCEL',
  /** Whiteboard request initiated by tutor */
  Init = 'INIT'
}

/** whiteboard session communication channel */
export type WhiteboardSession = {
  __typename?: 'WhiteboardSession';
  /** relevant data depending on status */
  data?: Maybe<Scalars['String']>;
  status: WhiteboardSessionEnum;
  userid?: Maybe<Scalars['ID']>;
};

/** Whiteboard session channel */
export enum WhiteboardSessionEnum {
  QuestionAnswered = 'QUESTION_ANSWERED',
  QuestionLoaded = 'QUESTION_LOADED',
  ScreensharedEnded = 'SCREENSHARED_ENDED',
  ScreenshareRequest = 'SCREENSHARE_REQUEST',
  ScreenshareRequestStop = 'SCREENSHARE_REQUEST_STOP',
  ScreenshareStarted = 'SCREENSHARE_STARTED',
  Screenshot = 'SCREENSHOT',
  ScreenshotRequest = 'SCREENSHOT_REQUEST',
  Start = 'START',
  /** Student accepected the request for whiteboard session */
  StudentAccept = 'STUDENT_ACCEPT',
  /** Student rejected the whiteboard session request */
  StudentCancel = 'STUDENT_CANCEL',
  UrlLoaded = 'URL_LOADED',
  WhiteboardExit = 'WHITEBOARD_EXIT',
  WhiteboardHide = 'WHITEBOARD_HIDE',
  WhiteboardShow = 'WHITEBOARD_SHOW'
}

/** Type of zoom link */
export enum ZoomLinkType {
  Normal = 'NORMAL',
  Webclient = 'WEBCLIENT'
}

export type DeleteSubscriberRecordsMutationVariables = Exact<{
  subscriberId: Scalars['Int'];
}>;


export type DeleteSubscriberRecordsMutation = { __typename?: 'Mutation', deleteSubscriberRecords: number, recurlyCloseAccount: string };

export type TodaysPresentationsQueryVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  limitToOwn?: InputMaybe<Scalars['Boolean']>;
}>;


export type TodaysPresentationsQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'Appointment', id: string, appointmentStartTime: string, eventType?: string | null, email?: string | null, firstName?: string | null, lastName: string, postcode?: string | null, mobile?: string | null, outcome?: AppointmentOutcome | null, outcomeAnalysis?: AppointmentOutcomeAnalysis | null, callbackDate?: string | null, consultant?: { __typename?: 'Consultant', firstName: string, lastName: string } | null } | { __typename?: 'AppointmentNoSale', id: string, appointmentStartTime: string, eventType?: string | null, email?: string | null, firstName?: string | null, lastName: string, postcode?: string | null, mobile?: string | null, outcome?: AppointmentOutcome | null, outcomeAnalysis?: AppointmentOutcomeAnalysis | null, callbackDate?: string | null, consultant?: { __typename?: 'Consultant', firstName: string, lastName: string } | null } | { __typename?: 'AppointmentSale', id: string, appointmentStartTime: string, eventType?: string | null, email?: string | null, firstName?: string | null, lastName: string, postcode?: string | null, mobile?: string | null, outcome?: AppointmentOutcome | null, outcomeAnalysis?: AppointmentOutcomeAnalysis | null, callbackDate?: string | null, consultant?: { __typename?: 'Consultant', firstName: string, lastName: string } | null }> };

export type OutcomeAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
  outcome: AppointmentOutcome;
  outcomeAnalysis?: InputMaybe<AppointmentOutcomeAnalysis>;
  callbackDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type OutcomeAppointmentMutation = { __typename?: 'Mutation', outcomeAppointment: { __typename?: 'Appointment', id: string, outcome?: AppointmentOutcome | null, outcomeAnalysis?: AppointmentOutcomeAnalysis | null, callbackDate?: string | null } };

export type WatchCustomerEventsSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WatchCustomerEventsSubscription = { __typename?: 'Subscription', salesConfirmationEvent: { __typename?: 'IrisSalesEvent', id: string, action: IrisSalesState, message: string, sentAt: string, activePagesOpen: number, position?: { __typename?: 'Position', timestamp: string, accuracy?: number | null, latitude?: number | null, longitude?: number | null, hasPosition: boolean, message: string } | null } };

export type GetSalesConfirmationStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSalesConfirmationStatusQuery = { __typename?: 'Query', saleConfirmationById: { __typename?: 'SaleConfirmation', confirmedAt?: string | null, activePagesOpen: number } };

export type SendSmsToCustomerMutationVariables = Exact<{
  data: SaleConfirmationData;
}>;


export type SendSmsToCustomerMutation = { __typename?: 'Mutation', salesConfirmationInit: { __typename?: 'SaleConfirmation', id: string, messageType: MessageType, email: string, phoneNumber: string } };

export type ResendSmsToCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
  type?: InputMaybe<MessageType>;
}>;


export type ResendSmsToCustomerMutation = { __typename?: 'Mutation', salesConfirmationResendSms: { __typename?: 'SaleConfirmation', id: string, messageType: MessageType, email: string, phoneNumber: string } };

export type SendSalesConfirmationEmailAndSmsMutationVariables = Exact<{
  id: Scalars['ID'];
  altEmail?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mandatePdfUrl?: InputMaybe<Scalars['String']>;
}>;


export type SendSalesConfirmationEmailAndSmsMutation = { __typename?: 'Mutation', salesConfirmationSendEmails: { __typename?: 'SaleConfirmation', id: string } };

export type SendSmsMutationVariables = Exact<{
  message: Scalars['String'];
  phone: Scalars['String'];
}>;


export type SendSmsMutation = { __typename?: 'Mutation', sendSms: unknown };

export type StripePaymentIntentMutationVariables = Exact<{
  amountInCents: Scalars['Int'];
  subscriberId: Scalars['Int'];
}>;


export type StripePaymentIntentMutation = { __typename?: 'Mutation', stripeSetupPaymentIntent: { __typename?: 'StripePaymentIntent', clientSecret: string, postCode: string, firstName: string, lastName: string, email: string, mobilePhone: string } };

export type StripeSendPaymentConfirmationEmailsMutationVariables = Exact<{
  amount: Scalars['Float'];
  paymentIntentId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postcode: Scalars['String'];
  subscriberId: Scalars['Int'];
}>;


export type StripeSendPaymentConfirmationEmailsMutation = { __typename?: 'Mutation', stripeSendPaymentConfirmationEmails: boolean };


export const DeleteSubscriberRecordsDocument = gql`
    mutation deleteSubscriberRecords($subscriberId: Int!) {
  deleteSubscriberRecords(subscriberId: $subscriberId)
  recurlyCloseAccount(subscriberId: $subscriberId)
}
    `;

/**
 * __useDeleteSubscriberRecordsMutation__
 *
 * To run a mutation, you first call `useDeleteSubscriberRecordsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscriberRecordsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteSubscriberRecordsMutation({
 *   variables: {
 *     subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useDeleteSubscriberRecordsMutation(options: VueApolloComposable.UseMutationOptions<DeleteSubscriberRecordsMutation, DeleteSubscriberRecordsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteSubscriberRecordsMutation, DeleteSubscriberRecordsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteSubscriberRecordsMutation, DeleteSubscriberRecordsMutationVariables>(DeleteSubscriberRecordsDocument, options);
}
export type DeleteSubscriberRecordsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteSubscriberRecordsMutation, DeleteSubscriberRecordsMutationVariables>;
export const TodaysPresentationsDocument = gql`
    query todaysPresentations($start: DateTime!, $end: DateTime!, $limitToOwn: Boolean) {
  appointments(status: CURRENT, start: $start, end: $end, limitToOwn: $limitToOwn) {
    id
    appointmentStartTime
    eventType
    email
    firstName
    lastName
    postcode
    mobile
    outcome
    outcomeAnalysis
    callbackDate
    consultant {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useTodaysPresentationsQuery__
 *
 * To run a query within a Vue component, call `useTodaysPresentationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodaysPresentationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTodaysPresentationsQuery({
 *   start: // value for 'start'
 *   end: // value for 'end'
 *   limitToOwn: // value for 'limitToOwn'
 * });
 */
export function useTodaysPresentationsQuery(variables: TodaysPresentationsQueryVariables | VueCompositionApi.Ref<TodaysPresentationsQueryVariables> | ReactiveFunction<TodaysPresentationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<TodaysPresentationsQuery, TodaysPresentationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TodaysPresentationsQuery, TodaysPresentationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TodaysPresentationsQuery, TodaysPresentationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TodaysPresentationsQuery, TodaysPresentationsQueryVariables>(TodaysPresentationsDocument, variables, options);
}
export function useTodaysPresentationsLazyQuery(variables: TodaysPresentationsQueryVariables | VueCompositionApi.Ref<TodaysPresentationsQueryVariables> | ReactiveFunction<TodaysPresentationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<TodaysPresentationsQuery, TodaysPresentationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TodaysPresentationsQuery, TodaysPresentationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TodaysPresentationsQuery, TodaysPresentationsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TodaysPresentationsQuery, TodaysPresentationsQueryVariables>(TodaysPresentationsDocument, variables, options);
}
export type TodaysPresentationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TodaysPresentationsQuery, TodaysPresentationsQueryVariables>;
export const OutcomeAppointmentDocument = gql`
    mutation outcomeAppointment($id: ID!, $outcome: AppointmentOutcome!, $outcomeAnalysis: AppointmentOutcomeAnalysis, $callbackDate: DateTime) {
  outcomeAppointment(
    id: $id
    outcome: $outcome
    outcomeAnalysis: $outcomeAnalysis
    callbackDate: $callbackDate
  ) {
    id
    outcome
    outcomeAnalysis
    callbackDate
  }
}
    `;

/**
 * __useOutcomeAppointmentMutation__
 *
 * To run a mutation, you first call `useOutcomeAppointmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useOutcomeAppointmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useOutcomeAppointmentMutation({
 *   variables: {
 *     id: // value for 'id'
 *     outcome: // value for 'outcome'
 *     outcomeAnalysis: // value for 'outcomeAnalysis'
 *     callbackDate: // value for 'callbackDate'
 *   },
 * });
 */
export function useOutcomeAppointmentMutation(options: VueApolloComposable.UseMutationOptions<OutcomeAppointmentMutation, OutcomeAppointmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<OutcomeAppointmentMutation, OutcomeAppointmentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<OutcomeAppointmentMutation, OutcomeAppointmentMutationVariables>(OutcomeAppointmentDocument, options);
}
export type OutcomeAppointmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<OutcomeAppointmentMutation, OutcomeAppointmentMutationVariables>;
export const WatchCustomerEventsDocument = gql`
    subscription watchCustomerEvents($id: ID!) {
  salesConfirmationEvent(id: $id) {
    id
    action
    message
    sentAt
    activePagesOpen
    position {
      timestamp
      accuracy
      latitude
      longitude
      hasPosition
      message
    }
  }
}
    `;

/**
 * __useWatchCustomerEventsSubscription__
 *
 * To run a query within a Vue component, call `useWatchCustomerEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchCustomerEventsSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useWatchCustomerEventsSubscription({
 *   id: // value for 'id'
 * });
 */
export function useWatchCustomerEventsSubscription(variables: WatchCustomerEventsSubscriptionVariables | VueCompositionApi.Ref<WatchCustomerEventsSubscriptionVariables> | ReactiveFunction<WatchCustomerEventsSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<WatchCustomerEventsSubscription, WatchCustomerEventsSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<WatchCustomerEventsSubscription, WatchCustomerEventsSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<WatchCustomerEventsSubscription, WatchCustomerEventsSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<WatchCustomerEventsSubscription, WatchCustomerEventsSubscriptionVariables>(WatchCustomerEventsDocument, variables, options);
}
export type WatchCustomerEventsSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<WatchCustomerEventsSubscription, WatchCustomerEventsSubscriptionVariables>;
export const GetSalesConfirmationStatusDocument = gql`
    query getSalesConfirmationStatus($id: ID!) {
  saleConfirmationById(id: $id) {
    confirmedAt
    activePagesOpen
  }
}
    `;

/**
 * __useGetSalesConfirmationStatusQuery__
 *
 * To run a query within a Vue component, call `useGetSalesConfirmationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesConfirmationStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSalesConfirmationStatusQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetSalesConfirmationStatusQuery(variables: GetSalesConfirmationStatusQueryVariables | VueCompositionApi.Ref<GetSalesConfirmationStatusQueryVariables> | ReactiveFunction<GetSalesConfirmationStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables>(GetSalesConfirmationStatusDocument, variables, options);
}
export function useGetSalesConfirmationStatusLazyQuery(variables: GetSalesConfirmationStatusQueryVariables | VueCompositionApi.Ref<GetSalesConfirmationStatusQueryVariables> | ReactiveFunction<GetSalesConfirmationStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables>(GetSalesConfirmationStatusDocument, variables, options);
}
export type GetSalesConfirmationStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSalesConfirmationStatusQuery, GetSalesConfirmationStatusQueryVariables>;
export const SendSmsToCustomerDocument = gql`
    mutation sendSmsToCustomer($data: SaleConfirmationData!) {
  salesConfirmationInit(data: $data) {
    id
    messageType
    email
    phoneNumber
  }
}
    `;

/**
 * __useSendSmsToCustomerMutation__
 *
 * To run a mutation, you first call `useSendSmsToCustomerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsToCustomerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendSmsToCustomerMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useSendSmsToCustomerMutation(options: VueApolloComposable.UseMutationOptions<SendSmsToCustomerMutation, SendSmsToCustomerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SendSmsToCustomerMutation, SendSmsToCustomerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SendSmsToCustomerMutation, SendSmsToCustomerMutationVariables>(SendSmsToCustomerDocument, options);
}
export type SendSmsToCustomerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SendSmsToCustomerMutation, SendSmsToCustomerMutationVariables>;
export const ResendSmsToCustomerDocument = gql`
    mutation resendSmsToCustomer($id: ID!, $type: MessageType) {
  salesConfirmationResendSms(id: $id, type: $type) {
    id
    messageType
    email
    phoneNumber
  }
}
    `;

/**
 * __useResendSmsToCustomerMutation__
 *
 * To run a mutation, you first call `useResendSmsToCustomerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResendSmsToCustomerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResendSmsToCustomerMutation({
 *   variables: {
 *     id: // value for 'id'
 *     type: // value for 'type'
 *   },
 * });
 */
export function useResendSmsToCustomerMutation(options: VueApolloComposable.UseMutationOptions<ResendSmsToCustomerMutation, ResendSmsToCustomerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ResendSmsToCustomerMutation, ResendSmsToCustomerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ResendSmsToCustomerMutation, ResendSmsToCustomerMutationVariables>(ResendSmsToCustomerDocument, options);
}
export type ResendSmsToCustomerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ResendSmsToCustomerMutation, ResendSmsToCustomerMutationVariables>;
export const SendSalesConfirmationEmailAndSmsDocument = gql`
    mutation sendSalesConfirmationEmailAndSms($id: ID!, $altEmail: String, $email: String!, $firstName: String!, $lastName: String!, $mandatePdfUrl: String) {
  salesConfirmationSendEmails(
    altEmail: $altEmail
    email: $email
    firstName: $firstName
    id: $id
    lastName: $lastName
    mandatePdfUrl: $mandatePdfUrl
  ) {
    id
  }
}
    `;

/**
 * __useSendSalesConfirmationEmailAndSmsMutation__
 *
 * To run a mutation, you first call `useSendSalesConfirmationEmailAndSmsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendSalesConfirmationEmailAndSmsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendSalesConfirmationEmailAndSmsMutation({
 *   variables: {
 *     id: // value for 'id'
 *     altEmail: // value for 'altEmail'
 *     email: // value for 'email'
 *     firstName: // value for 'firstName'
 *     lastName: // value for 'lastName'
 *     mandatePdfUrl: // value for 'mandatePdfUrl'
 *   },
 * });
 */
export function useSendSalesConfirmationEmailAndSmsMutation(options: VueApolloComposable.UseMutationOptions<SendSalesConfirmationEmailAndSmsMutation, SendSalesConfirmationEmailAndSmsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SendSalesConfirmationEmailAndSmsMutation, SendSalesConfirmationEmailAndSmsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SendSalesConfirmationEmailAndSmsMutation, SendSalesConfirmationEmailAndSmsMutationVariables>(SendSalesConfirmationEmailAndSmsDocument, options);
}
export type SendSalesConfirmationEmailAndSmsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SendSalesConfirmationEmailAndSmsMutation, SendSalesConfirmationEmailAndSmsMutationVariables>;
export const SendSmsDocument = gql`
    mutation sendSms($message: String!, $phone: String!) {
  sendSms(message: $message, phone: $phone)
}
    `;

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendSmsMutation({
 *   variables: {
 *     message: // value for 'message'
 *     phone: // value for 'phone'
 *   },
 * });
 */
export function useSendSmsMutation(options: VueApolloComposable.UseMutationOptions<SendSmsMutation, SendSmsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SendSmsMutation, SendSmsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SendSmsMutation, SendSmsMutationVariables>(SendSmsDocument, options);
}
export type SendSmsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SendSmsMutation, SendSmsMutationVariables>;
export const StripePaymentIntentDocument = gql`
    mutation stripePaymentIntent($amountInCents: Int!, $subscriberId: Int!) {
  stripeSetupPaymentIntent(
    amountInCents: $amountInCents
    subscriberId: $subscriberId
  ) {
    clientSecret
    postCode
    firstName
    lastName
    email
    mobilePhone
  }
}
    `;

/**
 * __useStripePaymentIntentMutation__
 *
 * To run a mutation, you first call `useStripePaymentIntentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentIntentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useStripePaymentIntentMutation({
 *   variables: {
 *     amountInCents: // value for 'amountInCents'
 *     subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useStripePaymentIntentMutation(options: VueApolloComposable.UseMutationOptions<StripePaymentIntentMutation, StripePaymentIntentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<StripePaymentIntentMutation, StripePaymentIntentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<StripePaymentIntentMutation, StripePaymentIntentMutationVariables>(StripePaymentIntentDocument, options);
}
export type StripePaymentIntentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<StripePaymentIntentMutation, StripePaymentIntentMutationVariables>;
export const StripeSendPaymentConfirmationEmailsDocument = gql`
    mutation stripeSendPaymentConfirmationEmails($amount: Float!, $paymentIntentId: String!, $email: String!, $firstName: String!, $lastName: String!, $postcode: String!, $subscriberId: Int!) {
  stripeSendPaymentConfirmationEmails(
    amount: $amount
    paymentIntentId: $paymentIntentId
    subscriberEmail: $email
    subscriberFirstName: $firstName
    subscriberLastName: $lastName
    subscriberPostcode: $postcode
    subscriberId: $subscriberId
  )
}
    `;

/**
 * __useStripeSendPaymentConfirmationEmailsMutation__
 *
 * To run a mutation, you first call `useStripeSendPaymentConfirmationEmailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useStripeSendPaymentConfirmationEmailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useStripeSendPaymentConfirmationEmailsMutation({
 *   variables: {
 *     amount: // value for 'amount'
 *     paymentIntentId: // value for 'paymentIntentId'
 *     email: // value for 'email'
 *     firstName: // value for 'firstName'
 *     lastName: // value for 'lastName'
 *     postcode: // value for 'postcode'
 *     subscriberId: // value for 'subscriberId'
 *   },
 * });
 */
export function useStripeSendPaymentConfirmationEmailsMutation(options: VueApolloComposable.UseMutationOptions<StripeSendPaymentConfirmationEmailsMutation, StripeSendPaymentConfirmationEmailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<StripeSendPaymentConfirmationEmailsMutation, StripeSendPaymentConfirmationEmailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<StripeSendPaymentConfirmationEmailsMutation, StripeSendPaymentConfirmationEmailsMutationVariables>(StripeSendPaymentConfirmationEmailsDocument, options);
}
export type StripeSendPaymentConfirmationEmailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<StripeSendPaymentConfirmationEmailsMutation, StripeSendPaymentConfirmationEmailsMutationVariables>;